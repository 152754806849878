<template>
  <v-container>
    <v-layout row wrap justify-space-between class="mr-1 mb-2 mt-2">
      <v-spacer></v-spacer>
      <v-flex xs12 sm12 md12 lg12 class="text-right">
        <v-btn
          :color="
            button.selected
              ? $store.state.secondaryColor
              : $store.state.primaryColor
          "
          outlined
          class="mx-1 my-1"
          small
          v-for="button in menu"
          :key="button.title"
          @click="goTo(button.route)"
        >
          <v-icon small left>{{ button.icon }}</v-icon>
          <span class="caption text-capitalize">{{ button.title }}</span>
        </v-btn>
      </v-flex>
    </v-layout>
    <router-view></router-view>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    titleToggle: false,
  }),

  computed: {
    menu() {
      return [
        {
          icon: "mdi-account-plus",
          title: this.$t("create new user"),
          route: "RegisterUser",
          selected: true,
        },
        {
          icon: "mdi-file-document-edit",
          title: this.$t("logs"),
          route: "Logs",
          selected: false,
        },
      ];
    },
  },
  methods: {
    goTo(route) {
      for (var i = 0; i < this.menu.length; i++) {
        this.menu[i].selected = false;
      }
      var index = this.menu.findIndex((x) => x.route == route);
      this.menu[index].selected = true;
      if (index == 0) {
        this.titleToggle = !this.titleToggle;
        this.menu[0].icon = this.titleToggle
          ? "fas fa-users"
          : "mdi-account-plus";
        this.menu[0].title = this.titleToggle ? "Users" : "Create new user";
        this.menu[0].route = this.titleToggle ? "" : "create";
      }
      this.$router.push({ name: route });
    },
  },
};
</script>

<style scoped>
.clients {
  background: cornsilk;
}
</style>
